<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxLabel,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue';
import { BulletListLoader } from 'vue-content-loader';
import { isEmpty } from 'lodash-es';
import dayjs from 'dayjs';
import type { Optional } from '#types/utils';
import type {
  FlightBaseSuggestion,
  FlightAirportSuggestion,
  FlightSearchFormHistory,
  FlightSingleAirportSuggestion,
} from '#types/flight';
import {
  ref,
  computed,
  watch,
  toRef,
  refDebounced,
  useFlightService,
  useAsyncData,
  useI18n,
  useConfigStore,
  useFlightSearchStore,
  useTracker,
  hasNonLatin,
} from '#imports';

interface IAirportPickerProps {
  modelValue?: Optional<FlightAirportSuggestion, 'country' | 'countryName' | 'continent'>;
  label?: string;
  title?: string;
  placeholder?: string;
  wrapperClass?: string;
  depAirport?: string;
  airlineCode?: string;
  flightType: 'origin' | 'destination';
  isAirportOnly?: boolean;
  hideDefaultSuggestion?: boolean;
}

interface IAirportPickerEmits {
  (
    e: 'update:modelValue',
    value: Optional<FlightAirportSuggestion, 'country' | 'countryName' | 'continent'> | undefined,
  ): void;
  (e: 'keywords', value: string): void;
}

const props = defineProps<IAirportPickerProps>();
const emit = defineEmits<IAirportPickerEmits>();

const { t, locale } = useI18n();
const { jitsuEvent } = useTracker();
const config = useConfigStore();
const flightSearchStore = useFlightSearchStore();

const keyword = ref('');
const suggestionsMemo = ref<{ [keyword: string]: FlightAirportSuggestion[] }>({});
const isFetchingSuggestions = ref(false);
const airportPosition = ref(0);
const fetchCount = ref(0);

const keywordDebounced = refDebounced(keyword, 500);

const {
  data: defaultSuggestions,
  pending: isFetchingDefaultSuggestion,
  execute: fetchDefaultSuggestions,
} = useAsyncData(
  () =>
    useFlightService().getAirportDefaultSuggestions({
      lang: locale.value,
      country: config.country!,
      dptAirport: props.depAirport,
      airline: props.airlineCode,
    }),
  {
    server: false,
    immediate: false,
    pick: ['result'],
    default: () => ({ result: { groups: [] } }),
    watch: [toRef(props, 'depAirport')],
  },
);

const selectedAirport = computed({
  get: () => props.modelValue,
  set: (suggestion) => {
    emit('update:modelValue', suggestion);

    jitsuEvent('user-click-preference', {
      event_name: 'flight-autocomplete-select-searchresult',
      product: 'flight',
      object_name: 'auto-complete-search',
      object_parameter: 'search-result',
      user_input: keyword.value,
      type: props.flightType,
      position: airportPosition.value,
      selected_value: suggestion?.code ?? '',
    });
  },
});

const groupedSuggestions = computed(() => {
  if (props.hideDefaultSuggestion) return [];

  const suggestion: {
    title: string;
    suggestions: (FlightSingleAirportSuggestion | FlightSearchFormHistory)[];
  }[] = defaultSuggestions.value.result.groups.map((group) => ({
    title: group.name,
    suggestions: group.cities,
  }));

  const flightSearchHistory = getFlightSearchHistory(props.flightType);

  if (!isEmpty(flightSearchHistory))
    suggestion.unshift({
      title: 'search.recentsearch',
      suggestions: flightSearchHistory,
    });

  return suggestion;
});

const flattenedSuggestions = computed(() => {
  let flatSuggestions: (FlightAirportSuggestion & { isSubAirport?: boolean })[] = [];

  suggestionsMemo.value[keywordDebounced.value?.trim()]?.forEach((suggestion) => {
    if ((props.isAirportOnly && !suggestion.airports) || !props.isAirportOnly) flatSuggestions.push(suggestion);

    if (suggestion.airports) {
      flatSuggestions = flatSuggestions.concat(
        suggestion.airports.map((airport) => ({ ...airport, isSubAirport: !props.isAirportOnly })),
      );
    }
  });

  return flatSuggestions;
});

watch(keywordDebounced, (newKeyword) => {
  if (isKeywordSufficient(newKeyword)) {
    fetchSuggestions(newKeyword);
  }
});

watch(keyword, (keyword) => {
  emit('keywords', keyword);
});

const sanitize = (text: string) => {
  return text ? text.replace(/</g, '&lt;').replace(/>/g, '&gt;') : text;
};
const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\s/g, '|');
};
const isKeywordSufficient = (keyword: string) => keyword.length > 1 || (hasNonLatin(keyword) && keyword.length > 0);

const getInputDisplay = (suggestion: FlightBaseSuggestion) => `${suggestion.cityName} (${suggestion.code})`;

function getFlightSearchHistory(flightType: string): FlightSearchFormHistory[] {
  const searchHistory = flightSearchStore.searchHistory;

  if (!isEmpty(searchHistory)) {
    let modifyFlightHistory = searchHistory.map((history) => {
      const airportProperties = flightType === 'origin' ? history.depAirportProperties : history.arrAirportProperties;

      const airport = flightType === 'origin' ? history.depAirport : history.arrAirport;

      let airportDesc = `${airport} - ${airportProperties?.name}`;

      if (airportProperties?.distance)
        airportDesc =
          airportDesc + ` - ${t('global.distanceto', [airportProperties.distance, airportProperties.cityName])}`;

      return {
        airportName:
          `${airportProperties?.cityName}${
            airportProperties?.countryName ? `, ${airportProperties?.countryName}` : ''
          }` ?? '',
        airportDesc,
        value: airportProperties,
      };
    });

    modifyFlightHistory = modifyFlightHistory.filter(
      (value, index, self) => index === self.findIndex((t) => t.airportName === value.airportName),
    );

    return modifyFlightHistory;
  }

  return [];
}

async function fetchSuggestions(keyword: string) {
  keyword = keyword.trim();

  if (suggestionsMemo.value[keyword]) {
    return;
  }

  isFetchingSuggestions.value = true;

  try {
    const fetchAutocompleteTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line camelcase
    const { result, status_code } = await useFlightService().getAirportSuggestions({
      query: keyword,
      lang: locale.value,
      country: config.country!,
    });

    suggestionsMemo.value[keyword] = result;

    // eslint-disable-next-line camelcase
    if (status_code === 200) {
      const fetchDuration = dayjs().diff(dayjs(fetchAutocompleteTimestamp)) / 1000;
      fetchCount.value = ++fetchCount.value;

      if (!result) {
        jitsuEvent('flight-autocomplete-fetch-noresult', {
          type: props.flightType,
          user_input: keyword,
          fetch_count: fetchCount.value,
        });
      }

      const autoCompleteParams = {
        time_done: fetchDuration,
        route_type: props.flightType,
        user_input: keyword,
        total_result: result.length,
        any_result: !result ? 0 : 1,
      };

      jitsuEvent('system-fetch-autocomplete', {
        event_name: 'flight-autocomplete-fetch',
        product: 'flight',
        object_name: 'auto-complete-fetch',
        object_parameter: JSON.stringify(autoCompleteParams),
        fetch_count: fetchCount.value,
      });
    }
  } catch (e) {
  } finally {
    isFetchingSuggestions.value = false;
  }
}

function highlightKeyword(text: string) {
  if (keyword.value.length === 0) {
    return text;
  }

  const escapedKeyword = sanitize(escapeRegExp(keyword.value));

  text = sanitize(text);

  const re = new RegExp(escapedKeyword, 'gi');

  return text.replace(re, `<span class="text-primary">$&</span>`);
}

function onFocus(ev: Event & { target: HTMLInputElement }) {
  ev.target.select();
  keyword.value = '';

  if (
    (!defaultSuggestions.value?.result || defaultSuggestions.value.result.groups.length === 0) &&
    !props.hideDefaultSuggestion
  ) {
    fetchDefaultSuggestions();
  }
}

function onBlur() {
  fetchCount.value = 0;
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <Combobox
    v-slot="{ open }"
    v-model="selectedAirport"
    by="code"
  >
    <div class="relative h-full">
      <ComboboxButton
        as="div"
        class="cursor-pointer overflow-hidden rounded border-[0.5px] border-gray-light px-15 py-10 h-full"
        :class="[{ 'border-primary': open }, $attrs.class]"
      >
        <ComboboxLabel
          v-if="label"
          class="block text-small text-gray-dark"
        >
          {{ label }}
        </ComboboxLabel>

        <ComboboxInput
          class="block text-base font-bold p-0 w-full placeholder:text-gray border-0 focus:outline-none focus:ring-0"
          :data-testid="'flightSearch-' + placeholder + '-input'"
          :placeholder="placeholder"
          :display-value="(value) => getInputDisplay(value as FlightAirportSuggestion)"
          @change="keyword = $event.target.value"
          @focus="onFocus"
          @blur="onBlur"
        />
      </ComboboxButton>

      <ComboboxOptions
        as="div"
        class="absolute drop-shadow-md w-[751px] mt-5 z-10"
      >
        <div class="flex justify-between items-center rounded-t bg-whiter border-b-[0.5px] border-b-gray-lightest p-15">
          <div class="text-base font-bold">{{ title }}</div>

          <ComboboxButton>
            <ApzIcon
              icon="times"
              class="text-[17px]"
              data-testid="flightSearch-autocompleteClose-button"
              @vue:mounted="
                jitsuEvent('user-click-field', {
                  type: props.flightType,
                  event_name: 'flight-autocomplete-open',
                  product: 'flight',
                  object_name: 'auto-complete-search',
                  object_parameter: props.flightType,
                })
              "
              @vue:unmounted="
                jitsuEvent('user-close-autocomplete', {
                  product: 'flight',
                  object_name: 'autocomplete',
                  object_parameter: JSON.stringify({
                    user_input: keyword,
                    update_input: keyword ? 1 : 0,
                  }),
                  type: props.flightType,
                })
              "
            />
          </ComboboxButton>
        </div>

        <div class="overflow-y-auto max-h-[491px] bg-white rounded-b">
          <div
            v-if="
              (keyword.length === 0 && isFetchingDefaultSuggestion && !hideDefaultSuggestion) || isFetchingSuggestions
            "
            class="px-15"
          >
            <BulletListLoader />
          </div>

          <template v-else-if="keyword.length === 0">
            <div
              v-if="groupedSuggestions.length === 0"
              class="text-base font-bold text-gray-dark p-15"
            >
              {{ $t('global.nodefaultdatafound') }}
              <br />
              <span class="text-small">{{ $t('global.nodefaultdatafoundesc') }}</span>
            </div>

            <div
              v-else
              class="flex flex-col gap-y-15 p-15"
            >
              <div
                v-for="group in groupedSuggestions"
                :key="group.title"
              >
                <div v-if="group.title === 'search.recentsearch'">
                  <div class="flex items-center text-small font-bold text-gray-dark mb-15">
                    <span class="whitespace-nowrap">{{ $t(`${group.title}`) }}</span>
                    <div class="h-[0.5px] flex-1 bg-gray-lightest ml-15" />
                  </div>
                  <div class="flex flex-col gap-y-20">
                    <ComboboxOption
                      v-for="(suggestion, index) in (group.suggestions as FlightSearchFormHistory[])"
                      :key="index"
                      v-slot="{ active }"
                      :value="suggestion.value"
                      as="template"
                      class="-m-15"
                      @click="
                        () => {
                          jitsuEvent('user-click-preference', {
                            event_name: 'flight-autocomplete-select-recentsearch',
                            product: 'flight',
                            object_name: 'auto-complete-search',
                            object_parameter: `${flightType}-recent`,
                            type: flightType,
                          });
                        }
                      "
                    >
                      <div
                        class="flex flex-wrap gap-x-20 items-center cursor-pointer p-15"
                        data-testid="flightSearch-recentSearch-container"
                        :class="{ 'bg-whiter': active }"
                        @click.stop="airportPosition = index"
                      >
                        <ApzIcon
                          icon="undo"
                          class="transform rotate-180"
                        />

                        <div>
                          <p class="font-bold">
                            {{ suggestion.airportName }}
                          </p>
                          <p class="text-small text-gray-dark">{{ suggestion.airportDesc }}</p>
                        </div>
                      </div>
                    </ComboboxOption>
                  </div>
                </div>

                <div v-else>
                  <div class="flex items-center text-small font-bold text-gray-dark mb-15">
                    <span class="whitespace-nowrap">{{ group.title }}</span>
                    <div class="h-[0.5px] flex-1 bg-gray-lightest ml-15" />
                  </div>
                  <div class="flex flex-wrap gap-x-20 gap-y-15">
                    <ComboboxOption
                      v-for="(suggestion, index) in (group.suggestions as FlightSingleAirportSuggestion[])"
                      :key="suggestion.code"
                      v-slot="{ active, selected }"
                      :value="suggestion"
                      as="template"
                      @click="
                        () => {
                          jitsuEvent('user-click-preference', {
                            product: 'flight',
                            event_name: 'flight-autocomplete-select-defaultlist',
                            object_name: 'auto-complete-search',
                            object_parameter: `${flightType}-defaultlist`,
                            selected_value: suggestion.code,
                            type: flightType,
                            value: suggestion.code,
                            position: index,
                          });
                        }
                      "
                    >
                      <div
                        data-testid="flightSearch-suggestion-button"
                        class="border-[0.5px] border-gray-light px-20 py-10 rounded text-base font-bold min-w-[65px] cursor-pointer"
                        :class="{ 'bg-whiter': selected || active }"
                        @click.stop="airportPosition = index"
                      >
                        {{ suggestion.cityName }}
                      </div>
                    </ComboboxOption>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div
            v-else-if="!isKeywordSufficient(keyword)"
            class="text-base font-bold text-gray-dark p-15"
          >
            {{ $t('global.keywordnotsuff') }}
            <br />
            <span class="text-small">{{ $t('global.typemorechar') }}</span>
          </div>

          <template v-else>
            <div v-if="flattenedSuggestions.length === 0">
              <div
                v-if="keywordDebounced.length > 1"
                class="text-base font-bold text-gray-dark p-15"
              >
                {{ $t('global.noresultfoundfor', { value: keywordDebounced }) }}
                <br />
                <span class="text-small">{{ $t('global.useotherkeyword') }}</span>
              </div>

              <BulletListLoader
                v-else
                class="px-15"
              />
            </div>

            <template v-else>
              <ComboboxOption
                v-for="(suggestion, index) in flattenedSuggestions"
                :key="suggestion.code"
                v-slot="{ active }"
                :value="suggestion"
                as="template"
              >
                <div
                  data-testid="autocomplete-perRow"
                  class="flex items-center p-15 cursor-pointer"
                  :class="{ 'bg-whiter': active, 'pl-45': suggestion.isSubAirport }"
                  @click.stop="airportPosition = index"
                >
                  <ApzIcon
                    v-if="suggestion.isSubAirport"
                    icon="level-up"
                    class="text-gray-darkest text-[15px] rotate-90 mr-15"
                  />
                  <ApzIcon
                    v-else
                    icon="plane-departure"
                    class="text-gray-darkest text-[15px] mr-15"
                  />

                  <div class="text-base">
                    <div
                      data-testid="autocomplete-name"
                      class="font-bold mb-5"
                      v-html="highlightKeyword(`${suggestion.cityName}, ${suggestion.countryName}`)"
                    />
                    <div class="flex">
                      <div
                        data-testid="autocomplete-airport-text"
                        class="text-gray-dark"
                        v-html="highlightKeyword(`${suggestion.code} - ${suggestion.name} &nbsp`)"
                      />

                      <span
                        v-if="suggestion.distance"
                        class="italic text-gray-dark"
                        data-testid="autocomplete-distance-text"
                      >
                        - {{ $t('global.distanceto', [suggestion.distance, suggestion.cityName]) }}
                      </span>
                    </div>
                  </div>
                </div>
              </ComboboxOption>
            </template>
          </template>
        </div>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>
